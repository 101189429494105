// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("./../../../src/templates/AboutUs/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-booking-confirmation-booking-confirmation-tsx": () => import("./../../../src/templates/BookingConfirmation/BookingConfirmation.tsx" /* webpackChunkName: "component---src-templates-booking-confirmation-booking-confirmation-tsx" */),
  "component---src-templates-booking-details-booking-details-tsx": () => import("./../../../src/templates/BookingDetails/BookingDetails.tsx" /* webpackChunkName: "component---src-templates-booking-details-booking-details-tsx" */),
  "component---src-templates-brand-brand-tsx": () => import("./../../../src/templates/Brand/Brand.tsx" /* webpackChunkName: "component---src-templates-brand-brand-tsx" */),
  "component---src-templates-cancel-payment-booking-cancel-payment-booking-tsx": () => import("./../../../src/templates/CancelPaymentBooking/CancelPaymentBooking.tsx" /* webpackChunkName: "component---src-templates-cancel-payment-booking-cancel-payment-booking-tsx" */),
  "component---src-templates-data-privacy-and-cookies-policy-data-privacy-and-cookies-policy-tsx": () => import("./../../../src/templates/DataPrivacyAndCookiesPolicy/DataPrivacyAndCookiesPolicy.tsx" /* webpackChunkName: "component---src-templates-data-privacy-and-cookies-policy-data-privacy-and-cookies-policy-tsx" */),
  "component---src-templates-legal-notices-legal-notices-tsx": () => import("./../../../src/templates/LegalNotices/LegalNotices.tsx" /* webpackChunkName: "component---src-templates-legal-notices-legal-notices-tsx" */),
  "component---src-templates-main-main-tsx": () => import("./../../../src/templates/Main/Main.tsx" /* webpackChunkName: "component---src-templates-main-main-tsx" */),
  "component---src-templates-mobile-checkout-index-tsx": () => import("./../../../src/templates/MobileCheckout/index.tsx" /* webpackChunkName: "component---src-templates-mobile-checkout-index-tsx" */)
}

